<template>
  <b-button-group>
    <b-button
      v-for="option in options"
      :key="`option_${option.value}`"
      :size="size"
      @click="selectOption(option)"
      v-b-tooltip.hover
      :title="option.title"
      class="text-nowrap"
      :variant="getVariant(option)"
      :class="getClass(option)"
      :disabled="disabled"
    >
      <slot :option="option">
        {{ option.text }}
      </slot>
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  name: 'ButtonSelect',
  components: {},
  props: {
    value: {
      type: undefined,
    },
    options: {
      type: Array,
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    isActive(option) {
      return this.value === option.value;
    },
    selectOption(option) {
      if (this.value === option.value && this.clearable === true) {
        this.$emit('input', null);
        return;
      }
      this.$emit('input', option.value);
    },
    getClass(option) {
      const classes = [];
      const isActive = this.isActive(option);
      if (option.class) {
        classes.push(option.class);
      }
      if (option.activeClass && isActive) {
        classes.push(option.activeClass);
      }
      return classes;
    },
    getVariant(option) {
      const isActive = this.isActive(option);
      if (option.activeVariant && isActive) {
        return option.activeVariant;
      }
      return isActive ? 'primary' : 'white';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
